import 'bootstrap';
import './swiper'
import {createApp} from 'vue';
import {createPinia} from 'pinia'

import simtarifflist from './views/SimTariffList.vue';
import mobiletarifflist from './views/MobileTariffList.vue';
import variantselector from './views/VariantSelector.vue';
import comparemobilephones from './views/CompareMobilePhones.vue';
import newsletter from './views/Newsletter.vue';
// import simtopdeal from './views/SimTopDeal.vue';
// import mobiletopdeal from './views/MobileTopDeal.vue';

const pinia = createPinia()

const newsletterSignup = createApp({
    components: {
        'newsletter': newsletter,
    }
})
newsletterSignup.mount('#newsletter-signup')

const simTariffListApp = createApp({
    components: {
        'simtarifflist': simtarifflist,
    }
})
simTariffListApp.mount('#sim-tariff-list')

const mobileTariffListApp = createApp({
    components: {
        'mobiletarifflist': mobiletarifflist,
    }
})
mobileTariffListApp.use(pinia)
mobileTariffListApp.mount('#mobile-tariff-list')

const variantSelectorApp = createApp({
    components: {
        'variantselector': variantselector,
    }
})
variantSelectorApp.use(pinia)
variantSelectorApp.mount('#variant-selector')

const comparemobilephonesApp = createApp({
    components: {
        'comparemobilephones': comparemobilephones,
    }
})
comparemobilephonesApp.use(pinia)
comparemobilephonesApp.mount('#compare-mobile-phones')
// const simTopDealApp = createApp({
//     components: {
//         'simtopdeal': simtopdeal,
//     }
// })
// simTopDealApp.mount('#sim-top-deal')

// const mobileTopDealApp = createApp({
//     components: {
//         'mobiletopdeal': mobiletopdeal,
//     }
// })
// mobileTopDealApp.mount('#mobile-top-deal')
