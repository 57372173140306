<template>
    <div id="variant-selector" class="d-none d-xl-flex flex-column gap-4 pt-4 pb-5">

        <div v-if="conditions" class="d-flex flex-column align-items-center align-items-xl-start justify-content-center gap-2">
            <span class="ph-text-white" style="font-size: 12px; font-weight: 500;">Condition:</span>
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start gap-3">
                <button type="button"
                        v-for="condition in conditions"
                        class="ph-btn ph-btn-tertiary"
                        :class="store.condition === condition ? 'active' : ''"
                        v-on:click="store.condition = condition">{{ condition }}
                </button>
            </div>
        </div>
        <div v-if="colours" class="d-flex flex-column align-items-center align-items-xl-start justify-content-center gap-2">
            <span class="ph-text-white" style="font-size: 12px; font-weight: 500;">Colour:</span>
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start gap-3">
                <button type="button"
                        v-for="colour in colours"
                        class="ph-btn ph-btn-tertiary"
                        :class="store.colour === colour ? 'active' : ''"
                        v-on:click="store.colour = colour">{{ colour }}
                </button>
            </div>
        </div>
        <div v-if="capacities" class="d-flex flex-column align-items-center align-items-xl-start justify-content-center gap-2">
            <span class="ph-text-white" style="font-size: 12px; font-weight: 500;">Storage:</span>
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start gap-3">
                <button type="button"
                        v-for="capacity in capacities"
                        class="ph-btn ph-btn-tertiary"
                        :class="store.capacity === capacity ? 'active' : ''"
                        v-on:click="store.capacity = capacity">{{ capacity }}
                </button>
            </div>
        </div>
    </div>

    <div class="d-xl-none flex-column gap-4 pt-4 pb-5 p-xl-0">
        <a data-bs-toggle="offcanvas" href="#offcanvasConfigure" role="button" aria-controls="offcanvasConfigure" class="ph-btn-textlink ph-text-white d-block d-xl-none mx-auto">Configure</a>

        <div class="offcanvas offcanvas-start ph-bg-primary ph-nav-mob p-0" tabindex="-1" id="offcanvasConfigure" aria-labelledby="offcanvasConfigure">

            <div class="offcanvas-header ph-bg-primary ph-nav-head-mob justify-content-between pt-5 p-4">
                <h5 class="ph-text-white mb-0" id="offcanvasConfigure">Configure</h5>
                <button type="button" class="p-0" data-bs-dismiss="offcanvas" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x ph-nav-head-mob-close" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </button>
            </div>

            <div class="offcanvas-body pt-0 p-4">
                <div v-if="conditions" class="mb-4">
                    <p class="ph-text-white text-start mb-2" style="font-weight: 600;">Condition:</p>
                    <div class="d-flex flex-wrap align-items-center justify-content-start gap-3">
                        <button type="button"
                                v-for="condition in conditions"
                                class="ph-btn ph-btn-tertiary"
                                :class="store.condition === condition ? 'active' : ''"
                                v-on:click="store.condition = condition">{{ condition }}
                        </button>
                    </div>
                </div>
                <div v-if="colours" class="mb-4">
                    <p class="ph-text-white text-start mb-2" style="font-weight: 600;">Colour:</p>
                    <div class="d-flex flex-wrap align-items-center justify-content-start gap-3">
                        <button type="button"
                                v-for="colour in colours"
                                class="ph-btn ph-btn-tertiary"
                                :class="store.colour === colour ? 'active' : ''"
                                v-on:click="store.colour = colour">{{ colour }}
                        </button>
                    </div>
                </div>
                <div v-if="capacities" class="mb-4">
                    <p class="ph-text-white text-start mb-2" style="font-weight: 600;">Storage:</p>
                    <div class="d-flex flex-wrap align-items-center justify-content-start gap-3">
                        <button type="button"
                                v-for="capacity in capacities"
                                class="ph-btn ph-btn-tertiary"
                                :class="store.capacity === capacity ? 'active' : ''"
                                v-on:click="store.capacity = capacity">{{ capacity }}
                        </button>
                    </div>
                </div>

                <button type="button" class="ph-btn ph-btn-primary d-block w-100" data-bs-dismiss="offcanvas" aria-label="Close">
                    Apply
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import {useVariantStore} from '../stores/variant'
import axios from 'axios';

export default {
    props: ['modelName', 'baseColour', 'baseCapacity'],
    data: () => ({
        colours: [],
        capacities: [],
        conditions: [],
    }),
    setup() {
        const store = useVariantStore();
        return {store};
    },
    mounted() {
        axios.get(`/ajax/mobile/variants?modelname=${this.modelName}`)
            .then(({ data }) => {
                const { colours, capacities, conditions } = data;
                this.colours = colours;
                this.capacities = capacities;
                this.conditions = conditions;

                // Set default store values based on provided or fetched data
                this.store.colour = this.baseColour || colours[0] || null;
                this.store.capacity = this.baseCapacity || capacities[0] || null;
                this.store.condition = conditions[0] || null;
            });
    },
}
</script>
