<template>
    <div class="container">
        <div class="row mb-4">
            <!--SORT BY-->
            <div class="col-6 col-md-4 d-xl-none">
                <a class="ph-btn ph-btn-secondary d-flex align-items-center justify-content-center h-100" data-bs-toggle="offcanvas" href="#offcanvasFilter" role="button" aria-controls="offcanvasFilter">Filter {{ this.modelName }} deals</a>
            </div>
            <div class="ph-tariff-sort col-6 col-md-4 col-lg-3 ms-md-auto filter">
                <div class="form-floating">
                    <select class="form-select" id="sort-by" v-model="sortBy">
                        <option value="feat">Featured</option>
                        <option value="mrc">Monthly cost</option>
                        <option value="tco">Total cost</option>
                        <option value="data">Data</option>
                    </select>
                    <label>Sort by</label>
                    <svg id="ph-tariff-sort-svg" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 375"><defs></defs>
                        <polygon style="fill:#1e1e1e;" points="750 0 375 375 0 0 750 0"/>
                    </svg>
                </div>
            </div>
            <!--SORT BY-->

            <!--FILTER SIDEBAR-->
            <div class="offcanvas offcanvas-start ph-bg-primary ph-nav-mob p-0" tabindex="-1" id="offcanvasFilter" aria-labelledby="offcanvasFilterLabel">
                <div class="offcanvas-header ph-bg-primary ph-nav-head-mob justify-content-between pt-5 p-4">
                    <h5 class="ph-text-white mb-0" id="offcanvasFilterLabel">Filter deals</h5>
                    <button type="button" class="p-0" data-bs-dismiss="offcanvas" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x ph-nav-head-mob-close" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </button>
                </div>
                <div class="offcanvas-body pt-0 p-4">

                    <p class="ph-text-white mb-2" style="font-weight: 600;">Ways to buy</p>
                    <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                        <button type="button"
                                :class="this.selectedWaysToBuy === 'contract' ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                v-on:click="setWaysToBuy('contract')">
                            Contract
                        </button>
                        <button type="button"
                                :class="this.selectedWaysToBuy === 'simfree' ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                v-on:click="setWaysToBuy('simfree')">
                            SIM free
                        </button>
                    </div>

                    <p class="ph-text-white mt-4 mb-2" style="font-weight: 600;">Network</p>
                    <div class="ph-tariff-filter-grid">
                        <button type="button" v-for="network in filterNetworks"
                                :class="this.selectedNetworks.includes(network.slug) ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                :data-value="network.slug"
                                v-on:click="setNetwork(network.slug)">
                            <img :src=network.logo_white loading="lazy" height="30" :alt="network.name"/><br>
                        </button>
                    </div>

                    <p class="ph-text-white mt-4 mb-2" style="font-weight: 600;">Monthly cost</p>
                    <div class="ph-tariff-filter-grid">
                        <button type="button" v-for="(value, key) in filterLineRental"
                                :class="selectedLineRental === key ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                :data-value="key"
                                v-on:click="setLineRental(key)" style="color: #fff!important;">
                            <span class="" style="font-size: 12px;">up to </span>
                            {{ value }}
                        </button>
                    </div>

                    <p class="ph-text-white mt-4 mb-2" style="font-weight: 600;">Upfront cost</p>
                    <div class="ph-tariff-filter-grid">
                        <button type="button" v-for="(value, key) in filterUpfront"
                                :class="(selectedUpfront !== false && Number(selectedUpfront) === Number(key)) ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                :data-value="key"
                                v-on:click="setUpfront(Number(key))" style="color: #fff!important;">
                            <span class="" style="font-size: 12px;">up to </span>
                            {{ value }}
                        </button>
                    </div>

                    <p class="ph-text-white mt-4 mb-2" style="font-weight: 600;">Data</p>
                    <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                        <button type="button" v-for="(value, key) in filterData"
                                :class="selectedData === key ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                :data-value="key"
                                v-on:click="setData(key)" style="color: #fff!important;">
                            {{ value }}
                        </button>
                    </div>

                    <p class="ph-text-white mt-4 mb-2" style="font-weight: 600;">Contract length</p>
                    <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                        <button type="button" v-for="(value, key) in filterLengths"
                                :class="selectedLength === key ? 'ph-btn ph-btn-tertiary active' : 'ph-btn ph-btn-tertiary'"
                                :data-value="key"
                                v-on:click="setLength(key)" style="color: #fff!important;">
                            <span v-html="value"></span>
                        </button>
                    </div>

                    <a class="ph-btn ph-btn-secondary d-block mt-4" id="reset-filter-button"
                       v-on:click="resetFilter()">Reset</a>
                    <a class="ph-btn ph-btn-primary d-block mt-2" id="apply-filter-button"
                       data-bs-dismiss="offcanvas" aria-label="Close">Apply</a>
                </div>
            </div>
        </div>
        <!--FILTER SIDEBAR-->

        <div class="row">
            <!--FILTER-->
            <div class="d-none d-xl-block col-4 col-xxl-3">
                <h5 class="mb-4">Filter {{ this.modelName }} deals</h5>

                <p class="mb-2" style="font-weight: 600;">Ways to buy</p>
                <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                    <button type="button"
                            :class="this.selectedWaysToBuy === 'contract' ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            v-on:click="setWaysToBuy('contract')">
                        Contract
                    </button>
                    <button type="button"
                            :class="this.selectedWaysToBuy === 'simfree' ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            v-on:click="setWaysToBuy('simfree')">
                        SIM free
                    </button>
                </div>

                <p class="mt-4 mb-2" style="font-weight: 600;">Network</p>
                <div class="ph-tariff-filter-grid">
                    <button type="button" v-for="network in filterNetworks"
                            :class="this.selectedNetworks.includes(network.slug) ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            :data-value="network.slug"
                            v-on:click="setNetwork(network.slug)">
                        <img :src=network.logo loading="lazy" height="30" :alt="network.name"/><br>
                    </button>
                </div>

                <p class="mt-4 mb-2" style="font-weight: 600;">Monthly cost</p>
                <div class="ph-tariff-filter-grid">
                    <button type="button" v-for="(value, key) in filterLineRental"
                            :class="selectedLineRental === key ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            :data-value="key"
                            v-on:click="setLineRental(key)">
                        <span class="" style="font-size: 12px;">up to </span>
                        {{ value }}
                    </button>
                </div>

                <p class="mt-4 mb-2" style="font-weight: 600;">Upfront cost</p>
                <div class="ph-tariff-filter-grid">
                    <button type="button" v-for="(value, key) in filterUpfront"
                            :class="(selectedUpfront !== false && Number(selectedUpfront) === Number(key)) ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            :data-value="key"
                            v-on:click="setUpfront(Number(key))">
                        <span class="" style="font-size: 12px;">up to </span>
                        {{ value }}
                    </button>
                </div>

                <p class="mt-4 mb-2" style="font-weight: 600;">Data</p>
                <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                    <button type="button" v-for="(value, key) in filterData"
                            :class="selectedData === key ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            :data-value="key"
                            v-on:click="setData(key)">
                        {{ value }}
                    </button>
                </div>

                <p class="mt-4 mb-2" style="font-weight: 600;">Contract length</p>
                <div class="ph-tariff-filter-grid" style="grid-template-columns: repeat(2, 1fr);">
                    <button type="button" v-for="(value, key) in filterLengths"
                            :class="selectedLength === key ? 'ph-btn ph-btn-light active' : 'ph-btn ph-btn-light'"
                            :data-value="key"
                            v-on:click="setLength(key)">
                        <span v-html="value"></span>
                    </button>
                </div>

                <a class="ph-btn ph-btn-secondary d-block mt-4" id="reset-filter-button"
                   v-on:click="resetFilter()">Reset</a>
                <a class="ph-btn ph-btn-primary d-block mt-2" id="apply-filter-button"
                   data-bs-dismiss="offcanvas" aria-label="Close">Apply</a>
            </div>
            <!--FILTER-->
            <!--TARIFF LIST-->
            <div class="col-12 col-xl-8 col-xxl-9">
                <div v-if="this.selectedWaysToBuy === 'contract'">
                    <!--PLACEHOLDER-->
                    <div v-if="placeholders" class="ph-deal-tile-primary p-4 mb-3"
                         v-for="placeholder in placeholders">
                        <div class="card-body pt-0">
                            <div class="row align-items-center">
                                <div class="w-auto">
                                    <svg class="bd-placeholder-img" width="50px" height="50px"
                                         xmlns="http://www.w3.org/2000/svg" role="img">
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                </div>
                                <div class="col-6 h-100">
                                    <h5 class="mb-0">
                                        <span class="placeholder col-12"></span>
                                    </h5>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-5 mb-3 mb-sm-0">
                                    <svg class="bd-placeholder-img" width="100%" height="100%"
                                            xmlns="http://www.w3.org/2000/svg" role="img">
                                        <rect width="100%" height="100%" fill="#1b352d"></rect>
                                    </svg>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-5 mb-3 mb-sm-0">
                                    <svg class="bd-placeholder-img" width="100%" height="100%"
                                            xmlns="http://www.w3.org/2000/svg" role="img">
                                        <rect width="100%" height="100%" fill="#1b352d"></rect>
                                    </svg>
                                </div>
                                <div class="col-12 col-lg-2 col-xl-4 col-xxl-2 mt-sm-3 mt-lg-0">
                                    <a class="ph-btn ph-btn-primary col-12 py-4 disabled placeholder"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--PLACEHOLDER-->
                    <!--NO DEALS-->
                    <div v-if="!deals.length && !placeholders" class="card mt-5 mb-3 border-0">
                        <h3 class="text-center">Unfortunately, there are no deals matching your search criteria, please try
                            again.</h3>
                    </div>
                    <!--NO DEALS-->
                    <!--TARIFFS-->
                    <transition-group name="fade" tag="div" v-if="deals.length">
                        <div v-for="deal in deals" :key="deal.id + '_' + refreshToken" class="ph-deal-tile-primary ph-text-white p-4 mb-3">
                            <h6 class="mb-0" v-if="deal.promoted && deal.pd_tag">PROMOTED DEAL</h6>
                            <div class="ph-stroke-primary my-4" v-if="deal.promoted"></div>
                            <div class="d-flex flex-row align-items-center mb-3 gap-3">
                                <img :src=deal.network_logo_white style="width:50px; height:auto;" loading="lazy" :alt="deal.network" />
                                <div class="d-none d-md-flex flex-row align-items-center gap-3 gap-sm-2 gap-xl-3">
                                    <h6 class="mb-0">{{ deal.network }}</h6>
                                    <svg id="ph-triangle-x3-right" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558.48 372.32"><defs></defs>
                                        <polygon class="triangle-1" points="372.32 0 558.48 186.16 372.32 372.32 372.32 0"/>
                                        <polygon class="triangle-2" points="186.16 0 372.32 186.16 186.16 372.32 186.16 0"/>
                                        <polygon class="triangle-3" points="0 0 186.16 186.16 0 372.32 0 0"/>
                                    </svg>
                                    <p class="mb-0">{{ deal.term }}-month contract</p>
                                </div>
                                <div v-if="deal.promoted && deal.pd_tag" class="ph-tag-callout ms-auto">{{ deal.pd_tag }}</div>
                            </div>
                            <div class="mb-3">
                                <div class="d-flex d-md-none flex-row align-items-center justify-content-center w-100 gap-3 gap-sm-2 gap-xl-3">
                                    <h6 class="mb-0">{{ deal.network }}</h6>
                                    <svg id="ph-triangle-x3-right" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558.48 372.32"><defs></defs>
                                        <polygon class="triangle-1" points="372.32 0 558.48 186.16 372.32 372.32 372.32 0"/>
                                        <polygon class="triangle-2" points="186.16 0 372.32 186.16 186.16 372.32 186.16 0"/>
                                        <polygon class="triangle-3" points="0 0 186.16 186.16 0 372.32 0 0"/>
                                    </svg>
                                    <p class="mb-0">{{ deal.term }}-month contract</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-5 mb-3 mb-sm-0 order-0">
                                    <div class="ph-deal-tile-primary tile-tariff-block d-flex flex-column px-2 py-3 text-center">
                                        <h5 class="mb-0">{{ deal.inc_data }}</h5>
                                        <p class="mb-0">data</p>
                                        <div class="my-2 my-lg-3" style="display:block;width:100%;height:2px;background-color:#1e1e1e;"></div>
                                        <p class="mb-0" style="font-weight:600;">Unlimited mins & texts</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-5 col-xl-4 col-xxl-5 mb-3 mb-sm-0 order-1">
                                    <div class="ph-deal-tile-primary tile-tariff-block d-flex flex-column px-2 py-3 text-center">
                                        <h5 class="mb-0">&pound;{{ deal.month_cost }}<span style="font-size:16px;"> a month</span></h5>
                                        <p class="mb-0">{{ deal.initial_cost }} upfront</p>
                                        <div class="my-2 my-lg-3" style="display:block;width:100%;height:2px;background-color:#1e1e1e;"></div>
                                        <p v-if="deal.network === 'Voxi'" class="mb-0" style="font-weight:600;">&pound;{{ deal.total_cost }} handset cost</p>
                                        <p v-else class="mb-0" style="font-weight:600;">&pound;{{ deal.total_cost }} total cost</p>
                                        <p v-if="deal.network === 'Voxi'"><small>SIM cost from £10 per month</small></p>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-2 col-xl-4 col-xxl-2 order-3 order-lg-2">
                                    <a :href=deal.basket_link tabindex="-1"
                                        class="d-block ph-btn ph-btn-primary mb-2 mt-0 mt-sm-3 mt-lg-0 buy-button"
                                        target="_blank"
                                        :data-awpid=deal.awpid>Get deal</a>
                                    <p class="d-flex flex-row flex-lg-column align-items-center justify-content-center gap-1 gap-lg-0 text-center mb-0"><span style="color: #777; font-size: 12px;">via</span>{{ deal.merchant }}</p>
                                </div>
                                <div class="ph-tariff-perks d-flex col-12 order-2 order-lg-3 gap-2">
                                    <span class="ph-tariff-perk-pill d-inline-block mb-3 mb-sm-0 mt-sm-3"
                                        v-if="deal.connectivity === '5G'">5G</span>
                                    <span class="ph-tariff-perk-pill d-inline-block mb-3 mb-sm-0 mt-sm-3"
                                        v-if="deal.signal !== deal.network">On {{ deal.signal }}'s network</span>
                                    <span class="ph-tariff-perk-pill d-inline-block mb-3 mb-sm-0 mt-sm-3"
                                        v-if="deal.promotional_text">{{ deal.promotional_text }}</span>
                                    <span class="ph-tariff-perk-pill d-inline-block mb-3 mb-sm-0 mt-sm-3"
                                        v-if="deal.gift">{{ deal.gift }}</span>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <!--TARIFFS-->
                    <!--MORE BUTTON-->
                    <div class="col-12 mt-4">
                        <button type="button" class="ph-btn ph-btn-secondary w-100" @click="incrementPage">
                            Show more deals
                        </button>
                    </div>
                    <!--MORE BUTTON-->
                </div>

                <div v-else-if="this.selectedWaysToBuy === 'simfree'">

                    <div v-if="simFreeDeals.length" class="ph-deal-tile-primary ph-text-white p-4 mb-3"
                         :style="(deal.promoted) ? 'border: 2px solid ' + deal.colour + '; background-color: ' + deal.colour + '15;' : ''"
                         v-for="deal in simFreeDeals">

                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-5">
                                <div class="row">
                                    <div class="col-12">
                                        <span
                                            class="d-inline-block py-1 px-3 rounded-pill text-nowrap me-2 mb-2 mb-md-0 bg-primary text-white mt-2"
                                            v-if="deal.promotional_text">{{ deal.promotional_text }}</span>
                                        <span
                                            class="d-inline-block py-1 px-3 rounded-pill text-nowrap me-2 mb-2 mb-md-0 bg-primary text-white mt-2"
                                            v-if="deal.gift">{{ deal.gift }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-12 col-lg-6">
                                        <div class="ph-deal-tile-primary tile-tariff-block d-flex flex-column px-2 py-3 mb-3 mb-lg-0 text-center">
                                            <h4 class="mb-1">{{ deal.initial_cost }}</h4>
                                            <p class="" style="font-weight:600;"> total cost</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6  text-center">
                                        <a :href=deal.basket_link tabindex="-1"
                                            class="d-block ph-btn ph-btn-primary buy-button mb-2" target="_blank"
                                            :data-awpid=deal.awpid>Get deal</a>
                                        <p class="mb-1" style="color: #777; font-size: 12px;">via</p>
                                        <p>{{ deal.merchant }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!simFreeDeals.length && !placeholders" class="card mt-5 mb-3 border-0">
                        <h3 class="text-center">Unfortunately, there are no deals matching your search criteria, please try
                            again.</h3>
                    </div>
                </div>
            </div>
            <!--TARIFF LIST-->
        </div>
    </div>
</template>

<script>
import {useVariantStore} from '../stores/variant'
import { debounce } from 'lodash';
import axios from 'axios';

export default {
    data() {
        return {
            name: 'tarifflist',
            deals: [],
            simFreeDeals: [],
            previousDeals: [],
            refreshToken: 0,
            placeholders: 5,
            sortBy: 'feat',
            page: 1,
            filterNetworks: [],
            filterLineRental: {
                5: '£5',
                10: '£10',
                15: '£15',
                20: '£20',
                25: '£25',
                30: '£30',
            },
            filterUpfront: {
                0: 'None',
                50: '£50',
                150: '£150',
                200: '£200',
                250: '£250',
                300: '£300',
            },
            filterData: {
                1000: '1GB+',
                2000: '2GB+',
                5000: '5GB+',
                10000: '10GB+',
                20000: '20GB+',
                999999: 'Unlimited',
            },
            filterLengths: {
                12: '12<span>-month</span>',
                24: '24<span>-month</span>',
                36: '36<span>-month</span>',
            },
            selectedWaysToBuy: 'contract',
            selectedNetworks: [],
            selectedLineRental: '',
            selectedUpfront: false,
            selectedData: '',
            selectedLength: '',
        }
    },
    setup() {
        const store = useVariantStore();
        // Debounce the getDeals function to delay it slightly
        const getDeals = debounce(() => {
            this.getDeals();
        }, 300); // Delay 300ms to avoid multiple AJAX calls.

        return {store};
    },
    props: ['pdata', 'pnetwork', 'plr', 'pupfront', 'plength', 'modelName', 'refurbs', 'simfree'],
    computed: {
        changeColour() {
            return this.store.colour;
        },
        changeCapacity() {
            return this.store.capacity;
        },
        changeCondition() {
            return this.store.condition;
        }
    },
    watch: {
        store: {
            handler() {
                this.getDeals();
            },
            deep: true, // Watch deeply to detect changes within the store
        },
        sortBy() {
            this.getDeals();
        },
        deals() {
            this.handleDealsChange();
        }
    },
    methods: {
        async getDeals(simfree = true) {
            let networks = '';
            if (this.selectedNetworks.length) {
                for (const value of this.selectedNetworks) {
                    networks += '&network[]=' + value;
                }
            }
            axios.get('/ajax/mobile/deals/' + this.sortBy + '?page=' + this.page + '&modelname=' + this.modelName + '&lr=' + this.selectedLineRental + '&upfront=' + this.selectedUpfront + '&data=' + this.selectedData + '&length=' + this.selectedLength + networks + '&colour=' + this.store.colour + '&capacity=' + this.store.capacity + '&condition=' + this.store.condition)
                .then((response) => {
                    if (this.page === 1) {
                        this.deals = response.data.data;
                    } else {
                        for (let i = 0; i < response.data.data.length; i++) {
                            this.deals.push(response.data.data[i]);
                        }
                    }
                    this.placeholders = 0;
                })

            if (simfree) {
                axios.get('/ajax/simfree/deals/?modelname=' + this.modelName + '&colour=' + this.store.colour + '&capacity=' + this.store.capacity + '&condition=' + this.store.condition)
                .then((response) => {
                    this.simFreeDeals = response.data.data;
                });
            }


            this.refreshToken++;
        },
        handleDealsChange() {
            // Manually trigger transition on deals change
            if (this.dealsHaveChanged()) {
                this.previousDeals = [...this.deals]; // Track the new state
            }
        },
        dealsHaveChanged() {
            // Compare previous deals to the new deals
            return JSON.stringify(this.deals) !== JSON.stringify(this.previousDeals);
        },
        setWaysToBuy(wayToBuy) {
            this.selectedWaysToBuy = wayToBuy;
        },
        setNetwork(filterNetwork)  {
            if (!this.selectedNetworks.includes(filterNetwork)) {
                this.selectedNetworks.push(filterNetwork);
            } else {
                const index = this.selectedNetworks.indexOf(filterNetwork);
                if (index > -1) {
                    this.selectedNetworks.splice(index, 1);
                }
            }

            this.resetPage();
            this.getDeals(false);
        },
        setLineRental(filterLineRental) {
            this.selectedLineRental === filterLineRental ? this.selectedLineRental = '' : this.selectedLineRental = filterLineRental;
            this.resetPage();
            this.getDeals(false);
        },
        setUpfront(filterUpfront) {
            this.selectedUpfront === filterUpfront ? this.selectedUpfront = false : this.selectedUpfront = filterUpfront;
            this.resetPage();
            this.getDeals(false);
        },
        setData(filterData) {
            this.selectedData === filterData ? this.selectedData = '' : this.selectedData = filterData;
            this.resetPage();
            this.getDeals(false);
        },
        setLength(filterLength) {
            this.selectedLength === filterLength ? this.selectedLength = '' : this.selectedLength = filterLength;
            this.resetPage();
            this.getDeals(false);
        },
        resetFilter() {
            this.selectedNetworks = '';
            this.selectedLineRental = '';
            this.selectedUpfront = false;
            this.selectedData = '';
            this.selectedLength = '';
            this.resetPage();
            this.getDeals();
        },
        incrementPage() {
            this.page += 1;
            this.getDeals(false);
        },
        resetPage() {
            this.page = 1;
        }
    },
    mounted() {

        if (this.simfree) {
            this.selectedWaysToBuy = 'simfree';
        }

        // Bind URL params if present
        this.selectedNetworks = this.pnetwork || [];
        this.selectedData = this.pdata || this.selectedData;
        this.selectedLineRental = this.plr || this.selectedLineRental;
        this.selectedUpfront = this.pupfront ? Number(this.pupfront) : false;
        this.selectedLength = this.plength || this.selectedLength;

        // Fetch available networks
        axios.get(`/ajax/mobile/deals/networks?modelname=${this.modelName}`)
            .then(response => {
                this.filterNetworks = response.data.data;
            });
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
