<template>
    <div id="variant-selector" class="d-flex flex-column gap-4 pt-4 pb-5">

        <div class="row">

            <div class="col-6 col-md-4 col-lg-3">
                <a data-bs-toggle="offcanvas" href="#offcanvasFilterBrands" role="button" aria-controls="offcanvasFilterBrands" class="ph-btn ph-btn-secondary d-flex align-items-center justify-content-center h-100">Filter Brands</a>
            </div>

            <div class="offcanvas offcanvas-start ph-bg-primary ph-nav-mob p-0" tabindex="-1" id="offcanvasFilterBrands" aria-labelledby="offcanvasFilterBrands">
                <div class="offcanvas-header ph-bg-primary ph-nav-head-mob justify-content-between pt-5 p-4">
                    <h5 class="ph-text-white mb-0" id="offcanvasConfigure">Filter Brands</h5>
                    <button type="button" class="p-0" data-bs-dismiss="offcanvas" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x ph-nav-head-mob-close" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </button>
                </div>
                <div class="offcanvas-body pt-0 p-4">
                    <div v-if="brands" class="d-flex flex-wrap align-items-start justify-content-start gap-3">
                        <button type="button"
                                v-for="brand in brands"
                                class="ph-btn ph-btn-tertiary"
                                :class="selectedBrand === brand.name ? 'active' : ''"
                                v-on:click="filterBrand(brand.name)">{{ brand.name }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="ph-tariff-sort col-6 col-md-4 col-lg-3 ms-md-auto filter">
                <div class="form-floating">
                    <select class="form-select" id="sort-by" v-model="sortBy">
                        <option value="alpha">Alphabetical</option>
                        <option value="mrc-lh">Monthly cost (low to high)</option>
                        <option value="mrc-hl">Monthly cost (high to low)</option>
                    </select>
                    <label>Sort by</label>
                    <svg id="ph-tariff-sort-svg" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 375"><defs></defs>
                        <polygon style="fill:#1e1e1e;" points="750 0 375 375 0 0 750 0"/>
                    </svg>
                </div>
            </div>
        </div>

        <div v-if="models" class="ph-brand-mob-grid d-grid py-3 py-md-5">
            <div v-for="model in filteredModels"
                 class="ph-deal-tile-primary ph-deal-tile-margin-top d-flex flex-column align-items-center w-100 p-4">
                <tag v-if="model.tag" class="ph-tag-callout callout-float">{{ model.tag }}</tag>
                <img 
                  :src="model.main_image ? 'https://pho.ams3.cdn.digitaloceanspaces.com/' + model.main_image : model.image" 
                  :alt="model.name" 
                  class="tile-handset-img pb-4" 
                  loading="lazy"
                />
                <div class="ph-text-white d-flex flex-column justify-content-center w-100 text-center mb-auto">
                    <h6>{{ model.brand.name }}</h6>
                    <h4>{{ model.name }}</h4>
                </div>
                <div class="ph-text-white d-flex flex-column justify-content-center w-100 text-center">
                    <span v-if="model.from_price" class="ph-deal-tile-span mb-1">£{{ model.from_price.telcos_initial_cost }} upfront cost</span>
                    <h5 v-if="model.from_price" class="mb-3"><span class="ph-deal-tile-span">from </span>£{{ model.from_price.telcos_month_cost }}<span class="ph-deal-tile-span"> a month</span></h5>
                </div>
                <div class="d-flex flex-column justify-content-center w-100">
                    <a :href="`/compare-mobile-phone-deals/brand/${model.brand.slug}/${model.slug}`" class="ph-btn ph-btn-primary">Compare deals</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: [],
    data: () => ({
        brands: [],
        models: [],
        selectedBrand: '',
        sortBy: 'alpha',
    }),
    mounted() {
        axios.get(`/ajax/mobile/models`)
            .then(({ data }) => {
                const { brands, models } = data;
                this.brands = brands;
                this.models = models;
            });
    },
    watch: {
        sortBy: function (val) {
            console.log(val);
        },
    },
    computed: {
        // Computed property to filter models based on selected brand
        filteredModels() {
            // Filter by brand if selected
            let filtered = this.selectedBrand ? this.models.filter(model => model.brand.name === this.selectedBrand) : this.models;

            // Sort based on selected sort criteria
            switch (this.sortBy) {
                case 'mrc-lh':
                    // Sort by monthly cost (ascending)
                    filtered.sort((a, b) => {
                        return a.from_price.telcos_month_cost - b.from_price.telcos_month_cost;
                    });
                    break;
                case 'mrc-hl':
                    // Sort by monthly cost (ascending)
                    filtered.sort((a, b) => {
                        return b.from_price.telcos_month_cost - a.from_price.telcos_month_cost;
                    });
                    break;
                case 'alpha':
                default:
                    // Alphabetical sorting by brand name + model name
                    filtered.sort((a, b) => {
                        const nameA = a.brand.name + ' ' + a.name;
                        const nameB = b.brand.name + ' ' + b.name;
                        return nameA.localeCompare(nameB);
                    });
                    break;
            }

            return filtered;
        }
    },
    methods: {
        // Toggle brand selection for filtering
        filterBrand: function(name) {
            if (this.selectedBrand === name) {
                this.selectedBrand = ''; // Deselect if same brand is clicked
            } else {
                this.selectedBrand = name;
            }

        }
    }
}
</script>
